<template>
    <div class="modal">
        <div class="card rounded shadow-lg">
            <div class="d-flex flex-column card-body text-center justify-content-center align-items-center">
                <i class="fa fa-info-circle fs-3 text-primary "></i>
                <p class="fs-6 fw-bolder my-3">{{this.modalMessage}}</p>
                <div class="d-flex p-1 justify-content-between">
                    <button @click="confirm" class="btn btn-dark mx-2">Yes</button>
                    <button id="cancel-button" @click="cancel" class="btn btn-outline-dark mx-2">No</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name:"ConfirmDialog",
        props:["modalMessage"],
        methods:{
            confirm(){
                this.$emit("confirm")
            },
            cancel(){
                this.$emit("cancel")
            }
        },
      mounted() {
        document.getElementById("cancel-button").focus();

      }
    }
</script>

<style scoped>
    .modal{
        top:0;
        z-index: 999999;
        height: 100%;
        width: 100%;
        background-color: rgba(0,0,0,0.2);
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .card{
        border-radius: 20px !important;
        width: 300px;
        height: 300px;
    }
    button{
        border-radius: 20px !important;
    }
</style>