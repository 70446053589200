<template>
    <div>
      <Loading v-if="loadingActive"/>
      <ConfirmDialog v-if="confirmModalActive" :modal-message="confirmModalMessage" v-on:confirm="confirm" v-on:cancel="cancel"/>
      <InfoModal v-if="modalActive" :modal-message="modalMessage" v-on:close-modal="closeModal"/>

      <b-container>

            <b-row class="d-flex justify-content-center align-items-center py-2">
                <b-col cols="12" md="10" lg="8" class="my-5 pt-5 ">
                    <div class="div-card p-2">
                        <div class="d-flex justify-content-between align-items-center div-card bg-light p-2 mb-3">
                            <span class="fw-bold fs-5">Update Parcel</span>
                            <button class="btn btn-danger rounded-circle" v-if="this.$store.state.user.email==='lineadmin@gmail.com'" @click="deleteParcel"><i class="fa fa-trash"></i></button>
                        </div>
                        <div class="m-0">
                            <form @submit.prevent="updateParcel" autocomplete="off">
                                <div class="row">
                                    <div class="col-12 col-md-6 col-lg-6">
                                        <div class="form-floating mb-3">
                                            <input type="text" id="ms-code" v-model="msCode" class="form-control" required placeholder="Enter Ms Code">
                                            <label for="ms-code">Ms Code</label>
                                        </div>

                                    </div>
                                    <div class="col-12 col-md-6 col-lg-6">
                                        <div class="form-floating mb-3">
                                            <input type="text" id="order-number" v-model="orderNumber" class="form-control" required  placeholder="Enter Order number">
                                            <label for="order-number">Order Number</label>
                                        </div>

                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-md-6 col-lg-6">
                                        <div class="form-floating mb-3">
                                            <input type="text" id="name" v-model="name" class="form-control" required  placeholder="Enter customer name">
                                            <label for="name">Customer Name</label>
                                        </div>

                                    </div>
                                    <div class="col-12 col-md-6 col-lg-6">
                                        <div class="form-floating mb-3">
                                            <input type="number" id="phone" v-model="phone" class="form-control" required  placeholder="Enter phone number">
                                            <label for="phone">Customer Phone</label>
                                        </div>

                                    </div>
                                    <div class="col-12 col-md-6 col-lg-6" :class="branch==='Maesot'?'d-none':''">
                                        <div class="form-floating mb-3">
<!--                                          :required="branch !== 'Maesot'"-->
                                            <input type="text" id="group" v-model="group" class="form-control" placeholder="Enter phone number">
                                            <label for="group">Group Name</label>
                                        </div>
                                        <!-- <input type="date" id="date" v-model="form.group" class="form-control mb-3" required placeholder="Enter Date">-->

                                    </div>
                                </div>
                                <div class="row bg-light p-1 m-1 div-card">

                                    <div class="col-12 col-md-6 col-lg-6 mx-auto" :class="branch==='Maesot'?'d-none':''">
                                        <label >Current City:</label>
                                        <select class="form-select mb-3 py-3" v-model="changedCity"  aria-label="default select example">
                                            <option value="" selected disabled >Current: </option>
                                            <option v-for="(cit , index) in cities" v-bind:key="index" :selected="index === 0" >
                                                {{cit}}
                                            </option>
                                        </select>

                                    </div>
                                  <div class="col-12 col-md-6 col-lg-6 mx-auto">
                                    <label>Date:</label>

                                    <input type="date" v-model="filterDate" class="form-control mb-3 py-3" required>
                                  </div>
                                    <div class="col-12 col-md-6 col-lg-6 mx-auto">
                                        <label>To:</label>
                                        <select class="form-select mb-3 py-3" v-model="city"  aria-label="default select example">
                                            <option value="" selected disabled >Send To: </option>
                                            <option v-for="(city , index) in cities" v-bind:key="index" :class="city === branch?'d-none':''" >
                                                {{city}}
                                            </option>
                                        </select>
                                    </div>

                                </div>
                                <div class="row my-2">
                                    <div class="col-12 col-md-6 col-lg-6 mx-auto">
                                        <button class= "btn btn-dark w-100 m-1" type="submit">Update</button>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
    import db, {timestamp} from "../../firebaseConfig";
    import "firebase/auth";
    import Loading from "../../components/Loading";
    import InfoModal from "../../components/InfoModal";
    import ConfirmDialog from "../../components/ConfirmDialog";
    export default {
        name:"UpdateParcel",
        components: {ConfirmDialog,InfoModal, Loading},
        data(){
            return{
                loadingActive:false,
                modalMessage:"",
                modalActive:false,
                confirmModalActive:false,
                confirmModalMessage:"",
                currentParcel:[],

            }
        },
        created() {
          this.$store.dispatch("getCurrentUser");
            this.$store.dispatch("getParcelByID", this.$route.params.parcelID);
            console.log()
        },
        computed:{
            parcelLists(){
                return this.$store.state.todayParcelLists;
            },
            cities(){
                return this.$store.state.cities;
            },

            msCode:{

                get(){
                    return this.$store.state.parcelMSCode;
                },
                set(payload){
                    this.$store.commit("updateParcelMSCode", payload);
                }
            },
            orderNumber:{
                get(){
                    return this.$store.state.parcelOrderNumber;
                },
                set(payload){
                    this.$store.commit("updateParcelOrderNumber", payload);
                }
            },
            name:{
                get(){
                    return this.$store.state.parcelName;
                },
                set(payload){
                    this.$store.commit("updateParcelName", payload);
                }
            },
            phone:{
                get(){
                    return this.$store.state.parcelPhone;
                },
                set(payload){
                    this.$store.commit("updateParcelPhone", payload);
                }
            },
            city:{
                get(){
                    return this.$store.state.parcelCity;
                },
                set(payload){
                    this.$store.commit("updateParcelCity", payload);
                }
            },
            group:{
                get(){
                    return this.$store.state.parcelGroup;
                },
                set(payload){
                    this.$store.commit("updateParcelGroup", payload);
                }
            },
            changedCity:{
                get(){
                    return this.$store.state.parcelUpdatedCity;
                },
                set(payload){
                    this.$store.commit("updateParcelUpdatedCity", payload);
                }
            },
            filterDate:{
            get(){
              return this.$store.state.parcelFilterDate;
            },
            set(payload){
              this.$store.commit("updateParcelFilterDate", payload);
            }
          },
            branch(){
                return this.$store.state.user.branch;
            },
          user(){
              return this.$store.state.user;
          }

        },

        /*async mounted(){
            this.currentParcel = await this.$store.state.todayParcelLists.filter((parcel) => {
                return parcel.parcelID === this.$route.params.parcelID;
            });
        },*/
        methods:{
            async updateParcel(){
                this.loadingActive = true;
                const database = await db.collection("parcelLists").doc(this.$route.params.parcelID)
                if(this.msCode !== "" &&
                    this.orderNumber!== "" &&
                    this.name !== "" &&
                    this.phone !== "" &&
                    this.city!==null &&
                    this.changedCity !== null
                ){
                    database.update({
                      msCode:this.msCode,
                      orderNumber:this.orderNumber,
                      name:this.name,
                      phone:this.phone,
                      to:this.city,
                      filterDate:this.filterDate,
                      changedCity:this.changedCity,
                      group:this.group,
                      updatedAt:timestamp
                    }).then(()=>{
                      console.log('update ok')
                        const updateData = db.collection("parcelLists").doc(this.$route.params.parcelID).collection('record').doc();
                        updateData.set({
                            date:timestamp,
                            process:"update",
                            id:updateData.id,
                            // to:this.changedCity,
                            branch:this.branch,
                            user:this.user.email
                        }).then(()=>{
                          console.log('record added')
                            this.loadingActive = false;
                            this.modalActive = true;
                            this.modalMessage = "Update OK"
                          /*setTimeout(()=>{
                            this.modalActive = false;
                            this.$router.replace({name:'ParcelLists'});
                          }, 1000);*/
                          return;
                        }).catch(err=>{
                            this.loadingActive = false;
                            this.modalActive = true;
                            this.modalMessage = err.message;
                        });

                    }).catch((err)=>{
                        this.loadingActive = false;
                        this.modalActive = true;
                        this.modalMessage = err.message;
                    });
                }
                else {
                    this.loadingActive = false;
                    this.modalActive = true;
                    this.modalMessage = "Fill completely!"
                }
            },
            cancel(){
                this.confirmModalActive = !this.confirmModalActive;
                this.modalMessage = "";
                this.confirmModalMessage="";
            },
            closeModal(){
                this.modalActive = !this.modalActive;
            },
            confirm(){
                const id = this.$route.params.parcelID;
                console.log(id);
                db.collection("parcelLists")
                    .doc(id)
                    .collection('record')
                    .get()
                    .then(querySnapshot =>{
                        querySnapshot.forEach(doc=>{
                            doc.ref.delete();
                        });
                        const getParcel = db.collection("parcelLists").doc(id);
                        getParcel.delete();
                        // this.$store.commit("filterTodayDeletedParcel",id);
                        this.$router.push({name:"ParcelLists"});
                    }).catch(err=>{
                    this.modalMessage=err.message;
                    this.modalActive = true;
                })

            },
            deleteParcel(){
                this.confirmModalActive = true;
                this.confirmModalMessage = "Are you sure to delete!"

            }
        }
    }
</script>

<style scoped>
    input, select, button, option, .card, .card-header{
        border-radius: 20px !important;
    }
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }
    .div-card{
        border: 2px solid #f7f7f7;
        border-radius: 20px;
    }
</style>